import { useEffect } from "react";
import ReactGA from "react-ga";

const GAComponent: React.FC = () => {
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA ?? '');
    // Track the initial page load
    ReactGA.pageview(window.location.pathname + window.location.search);

  }, []);

  return null;
};

export default GAComponent;
