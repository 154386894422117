import { useEffect, useState } from "react";
import { ModelCopoGarrafa } from "./Model/ModelCopoGarrafa";

function MenuCG(nomeMenu: string, jsonMenu: string) {
  const [menuData, setMenuData] = useState<ModelCopoGarrafa[]>([]);

  useEffect(() => {
    if (menuData.length === 0) {
      fetch(
        "https://api.menu.jsmotta.com/api/v1/mnu/OpenMenu/GetJSon?categoia=Vinhos",
        { cache: "no-store" }
      )
        .then((response) => response.json())
        .then((data) => {
          const dados = data as ModelCopoGarrafa[];

          const sortedData = dados.sort((a: any, b: any) =>
            a.descricao.localeCompare(b.descricao)
          );
          sortedData.map((menu: any, index: any) => (menu.selecionado = false));
          setMenuData(sortedData);
        });
    }
  }, []);

  const [menuDataVisible, setmenuDataVisible] = useState(false);

  const togglemenuData = () => {
    setmenuDataVisible(!menuDataVisible);
  };

  const showMe =
    "panel-collapse" +
    (menuDataVisible ? "collapse show openMainOpen" : "collapse");
  const showMeCaption = menuDataVisible ? "openMainOpen" : "collapsed";
  const menuDataVisibleYN = menuDataVisible ? "true" : "false";

  const rowSelected: string = "row colorMenuSelected";
  const rowUnselected: string = "row";

  const changeMenu = (menu: ModelCopoGarrafa) => () => {
    const newMenuData = menuData.map((item) => {
      if (item === menu) {
        item.selecionado = !item.selecionado;
      }
      return item;
    });
    setMenuData(newMenuData);
  };

  return (
    <div>
      <div className="panel-heading" role="tab" id="headingEighteen">
        <h4 className="panel-title">
          <a
            onClick={togglemenuData}
            className={showMeCaption}
            data-toggle="collapse"
            data-parent="#accordion"
            href="javascript:void(0);"
            aria-expanded={menuDataVisibleYN}
            aria-controls="collapseEighteen"
          >
            {nomeMenu}
          </a>
        </h4>
      </div>
      <div
        id="collapseEighteen"
        className={showMe}
        role="tabpanel"
        aria-labelledby="headingEighteen"
      >
        <div className="panel-body">
          <div className="openMenu">
            {menuDataVisible &&
              menuData
                .sort((a, b) => a.descricao.localeCompare(b.descricao))
                .map((menu, index) => (
                  <div
                    onMouseUp={changeMenu(menu)}
                    className={
                      menuData[index].selecionado ? rowSelected : rowUnselected
                    }
                    key={index}
                  >
                    <div className="col-md-8 col-8">
                      <p>{menu.descricao}</p>
                    </div>
                    <div className="col-md-2 col-2 text-right">
                      <p>{!menu.copo[0].startsWith("0") && (menu.copo)}</p>
                    </div>
                    <div className="col-md-2 col-2 text-right">
                      <p>{!menu.garrafa[0].startsWith("0") && (menu.garrafa)}</p>
                    </div>
                  </div>
                ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MenuCG;
